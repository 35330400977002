.signin-box {
  border-radius: 6px;
  border-width: 0;
  box-sizing: border-box;
  border-style: solid;
  border-color: #EDF2F7;
  background-color: white;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
}

.forgotPass {
  color: #828282;
}

.span-link{
  color: #ff9900;
  cursor: pointer;
}

.span-link:hover {
  text-decoration: underline;
}

.custom-input-signin {
  border-color: #c4c4c4;
}

.custom-input-signin:focus {
  border-color: #ff9900;
  box-shadow: none;
}

.custom-signin-button {
  border-radius: 0;
}

.custom-signin-button:hover {
  background-color: #ffac31;
}

@media only screen and (max-device-width: 768px) {
  
}
@media only screen and (max-device-width: 425px) {
  
}
@media only screen and (max-device-width: 375px) {
  
}
@media only screen and (max-device-width: 320px) {
  
}